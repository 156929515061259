import React, { useState, useLayoutEffect } from "react";
import { Accordion, Card, Button } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { useHistory } from "react-router-dom";
import { Endpoints } from "../../repository/Endpoints";
import { PostMaker } from "../../repository/RequestMaker";
const Folder = ({ material, setContentType }) => {
  // inits
  const history = new useHistory();
  const [folderOpen, setFolderOpen] = useState(true);
  // logics
  const iconType = (type) => {
    if (type == "PDF") {
      return <Icon.FileEarmarkText />;
    } else if (type == "TEST") {
      return <Icon.QuestionSquare />;
    } else {
      return <Icon.FileEarmarkPlay />;
    }
  };

  const onTest = (material_id) => {
    const materialIds = JSON.parse(localStorage.getItem("material_ids"));
    console.log(materialIds[0], material_id);
    if (materialIds[0] === material_id) {
      localStorage.setItem("material_id", material_id);
      history.push({
        pathname: "/quiz",
      });
    } else {
      alert("finish material prior to this to take quiz");
    }
  };

  const buttonType = (type, name, _material) => {
    if (type == "PDF") {
      return (
        <div
          className="col-9 btn d-flex justify-content-start"
          onClick={() => setContentType(_material.id, "PDF")}
          style={materialTitle}
        >
          {name}
        </div>
      );
    } else if (type == "TEST") {
      return (
        <div
          className="col-9 btn d-flex justify-content-start"
          onClick={() => onTest(_material.id)}
          style={materialTitle}
        >
          {name}
        </div>
      );
    } else {
      return (
        <div
          className="col-9 btn d-flex justify-content-start"
          onClick={() => {
            setContentType(_material.public_video_url, "VIDEO");
            localStorage.setItem("material_id", _material.id);
          }}
          style={materialTitle}
        >
          {name}
        </div>
      );
    }
  };

  return (
    <div style={{ marginTop: "5px" }}>
      <Accordion defaultActiveKey="0">
        <Card style={{ borderRadius: "5px" }}>
          <Accordion.Toggle
            eventKey="0"
            style={toggleTitle}
            onClick={() => setFolderOpen(!folderOpen)}
          >
            <div className="row">
              <div
                id={material.name}
                className="col-2 d-flex justify-content-center"
                style={{ alignItems: "center" }}
              >
                {folderOpen ? <Icon.Folder2Open /> : <Icon.FolderFill />}
              </div>
              <div className="col-8">
                <span>{material.name}</span>
              </div>
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              {material.materials.map((_material, _key) => {
                return (
                  <div className="row">
                    <div className="col-2"></div>
                    <div className="col-1 btn" id={_material.id}>
                      {iconType(_material.material_type.const)}
                    </div>
                    {buttonType(
                      _material.material_type.const,
                      _material.name,
                      _material
                    )}
                  </div>
                );
              })}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
  );
};

const toggleTitle = {
  border: "none",
  borderRadius: "5px",
  height: "4em",
  justifyContent: "start",
  textAlign: "start",
  background:
    "linear-gradient(90deg, rgba(255,255,255,1) 2%, rgba(234,240,240,1) 100%)",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
};

const materialTitle = {
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
};

export default Folder;
