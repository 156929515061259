import React, { useState, useEffect } from "react";
import "./style.css";
import { GetMaker, PostMaker } from "../../repository/RequestMaker";
import { Endpoints } from "../../repository/Endpoints";
import { useHistory } from "react-router-dom";
import QuizBody from "../quiz/QuizBody";
const CorrectAnswers = () => {
  // inits
  const material_id = localStorage.getItem("material_id");
  const enrollment_id = localStorage.getItem("enrollment_id");
  const [questions, setQuestions] = useState([]);
  // logics
  useEffect(() => {
    GetMaker(Endpoints.quiz + material_id)
      .then((res) => {
        setQuestions(res.data.questions);
        localStorage.setItem("test_id", res.data.test.id);
      })
      .then(() => {
        const results = JSON.parse(localStorage.getItem("results"));
        var ans_ids = [];
        for (let i = 0; i < results.correct_answers.length; i++) {
          const element = results.correct_answers[i];
          const answers = element.ans;
          for (let j = 0; j < answers.length; j++) {
            const answer = answers[j];
            ans_ids.push(answer);
          }
        }
        for (let i = 0; i < ans_ids.length; i++) {
          const element = ans_ids[i];
          document.getElementById(element).innerHTML= 'C';
        }
      });
  }, []);
  return (
    <>
      <div className="container">
        {questions === []
          ? "Loading"
          : questions.map((question, key) => {
              return <QuizBody index={key} question={question} />;
            })}
      </div>
    </>
  );
};

export default CorrectAnswers;
