import axios from "axios";
const api = "https://chub.compliance.monster/api";
export function PostMaker(data, path) {
  console.log(api + path);
  return axios
    .post(api + path, data, {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        return res;
      }
    })
    .catch((err) => {
      if (err.response) {
        if (err.response.data.message == "Unauthenticated.") {
          return;
        }
        alert(err.response.data.message);
        return;
      }
    });
}

export function GetMaker(path) {
  const fullpath = api + path;
  console.log(fullpath);
  return axios
    .get(fullpath, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        return res;
      }
    })
    .catch((err) => {
      if (err.response) {
        if (err.response.data.message == "Unauthenticated.") {
          return;
        }
        alert(err.response.data.message);
        return;
      }
    });
}

export function StreamMaker(path) {
  const fullpath = api + path;
  console.log(fullpath);
  return axios(fullpath, {
    method: "GET",
    responseType: "arraybuffer", //Force to receive data in a Blob Format
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then((response) => {
      console.log(response);
      // const file = new Blob([response.data]);
      // const fileURL = URL.createObjectURL(file);
      // return fileURL;
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export function SubtitleStreamMaker(path) {
  const fullpath = api + path;
  console.log(fullpath);
  return axios(fullpath, {
    method: "GET",
    responseType: "blob", //Force to receive data in a Blob Format
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then((response) => {
      const file = new Blob([response.data], {
        type: "text/vtt",
      });
      const fileURL = URL.createObjectURL(file);
      return fileURL;
    })
    .catch((error) => {
      console.log(error);
    });
}

export function PDFStreamMaker(path) {
  const fullpath = api + path;
  console.log(fullpath);
  return axios(fullpath, {
    method: "GET",
    responseType: "blob", //Force to receive data in a Blob Format
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then((response) => {
      const file = new Blob([response.data], {
        type: "application/pdf",
      });
      const fileURL = URL.createObjectURL(file);
      return fileURL;
    })
    .catch((error) => {
      console.log(error);
    });
}
