import React, { useState, useEffect } from "react";
import { Endpoints } from "../../repository/Endpoints";
import { GetMaker, PostMaker } from "../../repository/RequestMaker";
import CommonNavButtons from "../../utils/components/commonNavButtons/CommonNavButtons";
import EnrollCard from "../../utils/components/EnrollCard/EnrollCard";
import Navbar from "../../utils/components/navbar/Navbar";
import { useHistory } from "react-router-dom";
const CourseDetails = () => {
  // inits
  const course = JSON.parse(localStorage.getItem("course_details"));
  const [courses, setCourses] = useState([]);
  const [price, setPrice] = useState("");
  const [voucher, setVoucher] = useState(localStorage.getItem("voucher"));
  const [voucherValid, setVoucherValid] = useState(false);
  const user_id = localStorage.getItem("user_id");
  const profile_id = localStorage.getItem("profile_id");
  const history = new useHistory();
  const userType = localStorage.getItem("userType");
  // logics

  function shuffle(a) {
    for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
  }
  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

  const onEnroll = () => {
    localStorage.setItem("course_id", course.id);
    const enrollment_obj = {
      course_id: course.id,
      profile_id: profile_id,
      user_id: user_id,
    };
    if (!userType) {
      history.push({
        pathname: "/registertogo",
      });
    }
    PostMaker(enrollment_obj, Endpoints.enroll).then((response) => {
      if (response) {
        localStorage.setItem("enrollment_id", response.data.id);
        history.push({
          pathname: "/sections",
        });
      }
    });
  };

  const onVoucher = () => {
    PostMaker({}, Endpoints.voucher_validity + voucher).then((response) => {
      if (response) {
        localStorage.setItem(
          "tenant_id",
          response.data.course_allowance.tenant_id
        );
        setVoucherValid(true);
      }
    });
  };

  useEffect(() => {
    setPrice(getRandomInt(40));
    // GetMaker(Endpoints.list_courses + user_id).then((res) => {
    //   setCourses(shuffle(res.data).slice(0, 4));
    // });
  }, []);

  return (
    <>
      <Navbar>{userType === "normal" ? <CommonNavButtons /> : <></>}</Navbar>
      <div
        className="container-fluid"
        style={{ marginTop: "10px", marginLeft: "10px" }}
      >
        <div className="row">
          <div className="col-md">
            <img
              src={course.course_image}
              className="img-fluid"
              style={{ borderRadius: "5px" }}
            />
          </div>
          <div className="col-md">
            <div className="row">
              <div className="col">
                <h4 className="display-4">{course.name}</h4>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col">{course.short_description}</div>
            </div>
            <div className="row">
              <div className="col-6">
                <hr />
              </div>
            </div>
            <div className="row">
              <div className="col">{course.long_description}</div>
            </div>
            <div className="row">
              <div className="col-6">
                <hr />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div class="form-group">
                  <input
                    class="form-control"
                    placeholder="Voucher Code"
                    value={voucher}
                    onChange={(e) => setVoucher(e.target.value)}
                  />
                  <small class="form-text text-muted">
                    enter your voucher code
                  </small>
                </div>
              </div>
              <div className="col">
                <button className="btn btn-outline-primary" onClick={onVoucher}>
                  Enroll
                </button>
              </div>
            </div>
            {voucherValid ? (
              <>
                <div className="row">
                  <div className="col-6">
                    <hr />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <button
                      className="btn btn-outline-primary"
                      onClick={onEnroll}
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        {userType === "normal" ? (
          <>
            <div className="row" style={{ marginTop: "20px" }}>
              <div className="col">
                <hr />
                <span
                  class="btn btn-outline-primary"
                  style={{ cursor: "default" }}
                >
                  Discover
                </span>
              </div>
            </div>
            <div className="row">
              {courses === []
                ? "loading"
                : courses.map((oneCourse, key) => {
                    return <EnrollCard course={oneCourse} key={key} />;
                  })}
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default CourseDetails;
