import React, { useState } from "react";
import CommonNavButtons from "../../utils/components/commonNavButtons/CommonNavButtons";
import Navbar from "../../utils/components/navbar/Navbar";
import { Backgrounds } from "./backgrounds";
const Settings = () => {
  // inits
  const [backgrounds, setBackgrounds] = useState(Backgrounds);
  // logics
  const onBackground = (id) => {
    localStorage.setItem("background", id);
    window.location.reload();
  };
  return (
    <>
      <Navbar></Navbar>
      <div className="container-fluid">
        <div className="display-4">Select Background</div>
        <div className="row">
          <div className="col-6">
            <hr />
          </div>
        </div>
        <div className="container">
          <div className="row">
            {backgrounds.map((background, key) => {
              const image = Object.values(background.image)[0];
              const title = background.title;
              return (
                <div className="col-md-3">
                  <div
                    style={{ border: "1px solid black", cursor: "pointer" }}
                    onClick={() => {
                      onBackground(key + 1);
                    }}
                  >
                    <div
                      className="fill"
                      style={{
                        height: "14em",
                      }}
                    >
                      <img
                        src={image}
                        className="img-fluid"
                        style={productImageStyle}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
const productImageStyle = {
  borderTopLeftRadius: "2px",
  borderTopRightRadius: "2px",
  objectFit: "cover",
};

export default Settings;
