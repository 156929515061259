import React, { useState } from "react";
import { PostMaker } from "../../repository/RequestMaker";
import { Endpoints } from "../../repository/Endpoints";
import { useHistory } from "react-router-dom";
const LoginNormal = () => {
  //inits
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = new useHistory();

  //logics
  const onSubmit = (e) => {
    e.preventDefault();
    const data = {
      email: email,
      password: password,
    };
    PostMaker(data, Endpoints.login).then((res) => {
      if (res) {
        localStorage.setItem("profile_id", res.profile_id);
        localStorage.setItem("token", res.token);
        localStorage.setItem("user_id", res.user.id);
        localStorage.setItem("userType", "normal");
        history.push({
          pathname: "/home",
        });
      }
    });
  };
  return (
    <div className="col-md">
      <div className="jumbotron">
        <h4
          style={{
            fontSize: "1.6rem",
            fontWeight: "300",
            lineHeight: "1.2",
          }}
        >
          Start with Login
        </h4>
        {/* <p className="text-muted invisible">register</p> */}
        <hr />
        <form>
          <div class="form-group">
            <input
              class="form-control"
              placeholder="Email Address"
              onChange={(e) => setEmail(e.target.value)}
            />
            <small class="form-text text-muted">enter your email address</small>
          </div>

          <div class="form-group">
            <input
              class="form-control"
              placeholder="Password"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <small class="form-text text-muted">enter password</small>
          </div>

          <div class="form-group">
            <button
              className="btn btn-outline-primary"
              onClick={(e) => onSubmit(e)}
            >
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginNormal;
