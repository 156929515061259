import React from "react";
import "./style.css";
import { Link, useHistory } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import Logo from "../../assets/logo.png";
const Navbar = ({ children }) => {
  // inits
  const history = useHistory();
  // logics
  const onLogout = () => {
    history.replace({
      pathname: "/",
    });
  };
  return (
    <>
      <nav
        className="navbar navbar-expand-lg"
        style={{
          backgroundColor: "transparent",
        }}
      >
        <div className="container-fluid">
          <Link to="/home" className="navbar-brand">
            <img src={Logo} className="img-fluid" width="200" />
          </Link>
          <button
            className="navbar-toggler btn btn-outline-primary"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            style={{border:'none',outline: 'none'}}
          ><Icon.List /></button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <div className="d-flex" style={{ width: "100%" }}>
              {children}
            </div>
            <span className="d-flex">
              <button
                className="btn btn-outline-danger"
                style={{ border: "none" }}
                onClick={onLogout}
              >
                <Icon.Power />
              </button>
            </span>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
