import React from "react";
import "./style.css";
import { useHistory } from "react-router-dom";
import CommonNavButtons from "../../utils/components/commonNavButtons/CommonNavButtons";
import Navbar from "../../utils/components/navbar/Navbar";
import CorrectAnswers from "./CorrectAnswers";
import { PostMaker } from "../../repository/RequestMaker";
import { Endpoints } from "../../repository/Endpoints";
const Result = () => {
  //inits
  const history = useHistory();
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();
  today = mm + "." + dd + "." + yyyy;
  const status = localStorage.getItem("passed");

  //logics
  const getCertificate = () => {
    markMaterialComplete().then(() => {
      history.push({
        pathname: "/certificate",
      });
    });
  };

  const markMaterialComplete = async () => {
    const material_ids = JSON.parse(localStorage.getItem("material_ids"));
    const enrollment_id = localStorage.getItem("enrollment_id");
    for (let i = 0; i < material_ids.length; i++) {
      const material_id = material_ids[i];
      PostMaker(
        {
          section_material_id: material_id,
          course_enrollment_id: enrollment_id,
        },
        Endpoints.mark_quiz_done
      ).then((response) => {
        console.log(response.data);
      });
    }
  };
  const retakeCourse = () => {
    history.replace({
      pathname: "/sections",
    });
  };
  return (
    <>
      <Navbar>
        <CommonNavButtons />
      </Navbar>

      <table id="scorecard">
        <thead>
          <tr>
            <th>Course</th>
            <th>Score</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{localStorage.getItem("course_title")}</td>
            <td>{localStorage.getItem("percentage")}%</td>
            <td>{today}</td>
          </tr>
          <tr style={status === "true" ? { color: "green" } : { color: "red" }}>
            <td colSpan={"3"}>
              Status: {status === "true" ? "Passed" : "Failed"}
            </td>
          </tr>

          <tr>
            <td colSpan={"3"}>
              {status === "true" ? (
                <button
                  className="btn btn-outline-success"
                  onClick={getCertificate}
                >
                  Get Certificate
                </button>
              ) : (
                <>
                  <button
                    className="btn btn-outline-warning"
                    onClick={retakeCourse}
                  >
                    Retake Course
                  </button>
                </>
              )}
            </td>
          </tr>
        </tbody>
      </table>
      {localStorage.getItem("debugMode") ? (
        <div style={{ color: "red" }}>
          <div>
            ///////////////////////////////////////////////////////////////////////
          </div>
          <div>
            THIS IS DEBUG MODE REMOVE THE ANSWERS BY
            localStorage.removeItem('debugMode');window.location.reload()
          </div>
          <CorrectAnswers />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Result;
