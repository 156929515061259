import React from "react";
import { useHistory } from "react-router-dom";

const CommonNavButtons = () => {
  // inits
  const history = useHistory();
  // logics
  const onCourses = () => {
    history.replace({
      pathname: "/home",
    });
  };

  const onMyCourses = () => {
    history.replace({
      pathname: "/mycourses",
    });
  };
  return (
    <>
      <button className="nav-link btn" onClick={onCourses}>
        All Courses
      </button>
      <button className="nav-link btn" onClick={onMyCourses}>
        My Courses
      </button>
    </>
  );
};

export default CommonNavButtons;
