import React from "react";
import LoginNormal from "./LoginNormal";
import LoginVoucher from "./LoginVoucher";
import LoginBack from "../../utils/assets/loginback.png";
import Logo from "../../utils/assets/logo.png";

const LoginPortrait = () => {
  return (
    <div style={backgroundimage}>
      <div style={gradientDiv} className="d-flex justify-content-center">
        <div className="container-fluid">
          <div style={loginContainer}>
            <div className="row">
              <div className="col-md">
                <div className="jumbotron">
                  <img src={Logo} className="image-fluid" width="200" />
                </div>
              </div>
              <div className="col-md"></div>
            </div>
            <div className="row">
              <LoginNormal />
              <LoginVoucher />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const backgroundimage = {
  backgroundImage: `url(${LoginBack})`,
  backgroundRepeat: "no-repeat",
  backgroundAttachment: "fixed",
  backgroundPosition: "center",
  backgroundSize: "cover",
};

const gradientDiv = {
  alignItems: "center",
  height: "100vh",
  width: "100%",
  flexDirection: "column",
  background:
    "linear-gradient(146deg, rgba(255,255,255,1) 2%, rgba(7,78,155,0.8169642857142857) 100%)",
};

const loginContainer = {
  width: "100%",
  height: "100vh",
  background:
    "linear-gradient(146deg, rgba(255,255,255,1) 2%, rgba(234,240,240,0.5) 100%)",
  padding: "10px",
};

export default LoginPortrait;
