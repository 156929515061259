import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
const EnrollCard = ({ course, index, page }) => {
  //style
  const cardBackground = {
    backgroundImage: `url(${course.course_image})`,
    height: "20em",
    borderRadius: "5px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    position: "relative",
  };

  // inits
  const history = new useHistory();
  // logics
  const onEnroll = () => {
    localStorage.setItem("course_details", JSON.stringify(course));
    if (page === "home") {
      history.push({
        pathname: "/coursedetails",
      });
    } else {
      window.scroll(0, 0);
      history.replace({
        pathname: "/coursedetails",
      });
    }
  };
  return (
    <div
      id={index}
      className="btn col-md-3"
      style={{ marginTop: "15px" }}
      onClick={onEnroll}
    >
      <div style={cardBackground}>
        <div style={cardTint}>
          <div style={pushToBottom}>
            <div
              className="d-flex justify-content-start"
              style={infoCardBackground}
            >
              <div className="row d-flex justify-content-start">
                <div
                  className="col d-flex justify-content-start"
                  style={titleStyle}
                >
                  {course.name}
                </div>
              </div>
              <div className="row d-flex justify-content-start">
                <div
                  className="col d-flex justify-content-start"
                  style={subTitleStyle}
                >
                  {course.short_description}
                </div>
              </div>
              {/* <div className="d-flex justify-content-end">
                <button className="btn btn-outline-primary">Enroll</button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const cardTint = {
  width: "100%",
  height: "20em",
  borderRadius: "5px",
  padding: "10px",
  background: `linear-gradient(146deg, rgba(6,78,155,0.5) 0%, rgba(255,255,255,0.5) 100%)`,
};

const pushToBottom = {
  width: "100%",
  bottom: "0",
  left: "0",
  position: "absolute",
  padding: "10px",
};

const infoCardBackground = {
  flexDirection: "column",
  alignItems: "center",
  borderRadius: "5px",
  padding: "10px",
  height: "5em",
  background:
    "linear-gradient(146deg, rgba(255,255,255,0.8) 2%, rgba(234,240,240,0.3) 100%)",
};

const titleStyle = {
  fontSize: "1.2em",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
};

const subTitleStyle = {
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
};
export default EnrollCard;