import "./App.css";
import Login from "./pages/login/Login";
import Home from "./pages/home/Home";
import CourseDetails from "./pages/courseDetails/CourseDetails";
import MyCourses from "./pages/myCourses/MyCourses";
import Sections from "./pages/sections/Sections";
import Quiz from "./pages/quiz/Quiz";
import Result from "./pages/result/Result";
import Certificate from "./pages/certificate/Certificate";
import Background from "./utils/assets/back.png";
import { BrowserRouter as Router, Route } from "react-router-dom";
import TestingUI from "./pages/testingUI/TestingUI";
import RegisterToGo from "./pages/registerToGo/RegisterToGo";
import Settings from "./pages/settings/Settings";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    const background = localStorage.getItem("background");
    console.log("background", background);
    // document.getElementsByTagName(
    //   "body"
    // )[0].style.background = `url(${back})`;
    const element = document.getElementsByTagName("body")[0];
    for (let i = 1; i < 5; i++) {
      element.classList.remove("back" + i);
    }
    element.classList.add("back" + background);
  }, []);
  return (
    <>
      <Router>
        <Route exact path="/" component={Login} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/home" component={Home} />
        <Route exact path="/coursedetails" component={CourseDetails} />
        <Route exact path="/mycourses" component={MyCourses} />
        <Route exact path="/sections" component={Sections} />
        <Route exact path="/quiz" component={Quiz} />
        <Route exact path="/result" component={Result} />
        <Route exact path="/tui" component={TestingUI} />
        <Route exact path="/certificate" component={Certificate} />
        <Route exact path="/registertogo" component={RegisterToGo} />
        <Route exact path="/settings" component={Settings} />
      </Router>
    </>
  );
}

export default App;
