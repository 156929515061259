import React, { useEffect, useState } from "react";
import { Endpoints } from "../../repository/Endpoints";
import { GetMaker } from "../../repository/RequestMaker";
import CommonNavButtons from "../../utils/components/commonNavButtons/CommonNavButtons";
import Navbar from "../../utils/components/navbar/Navbar";
import MyCard from "./myCard";

const MyCourses = () => {
  // inits
  const user_id = localStorage.getItem("user_id");
  const [courses, setCourses] = useState([]);
  // logics
  useEffect(() => {
    localStorage.setItem("tennant_id", "00abcb6a-b13d-457b-b559-9109af207eac"); //for now
    GetMaker(Endpoints.my_courses + user_id).then((res) => {
      console.log(res.data);
      setCourses(res.data);
    });
  }, []);
  return (
    <>
      <Navbar>
        <CommonNavButtons />
      </Navbar>
      <div className="row" style={{ marginLeft: "auto", marginRight: "auto" }}>
        <div className="col">
          <h4 className="display-4">My Courses</h4>
          <hr />
        </div>
        <div className="col"></div>
      </div>
      <div className="row" style={{ marginLeft: "auto", marginRight: "auto" }}>
        {courses === [] ? (
          "Loading"
        ) : (
          <>
            {courses.map((course, key) => {
              return (
                <>
                  <MyCard
                    course={course.course}
                    enrollmentId={course.enrollment_id}
                  />
                </>
              );
            })}
          </>
        )}
      </div>
    </>
  );
};

export default MyCourses;
