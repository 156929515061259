export const Endpoints = {
  api: "https://chub.compliance.monster/api",
  login: "/login",
  list_courses: "/education_courses_for_user/",
  list_courses_voucher: "/education_courses_available_to_go/",
  my_courses: "/education_enrolled_courses_by_user_id/",
  enroll: "/education_course_enrollments",
  sections: "/education_sections_with_materials_by_course_id/",
  pdf: "/education_section_materials_get_file_stream/",
  quiz: "/education_section_materials_get_test/",
  result: "/education_test_result_ready_to_go",
  mark_quiz_done: "/education_material_progress",
  education_material_progress: "/education_material_progress",
  certificate: "/education_get_certificate_chub",
  dev_sections:
    "/education_sections_with_materials_by_course_id/a1fc934d-6c6f-4f87-af6c-3116a3517e35",
  voucher_validity: "/check_voucher/education/",
  register_to_go: "/register_to_go",
  material_progress_by_enrollment_id: "/material_progress_by_enrollment_id/",
  education_section_materials_get_video_pseudo_stream:
    "/education_section_materials_get_video_pseudo_stream/",
  sub: "/education_section_materials_get_subtitle_stream/",
};
