import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
const EnrollChip = ({ course, index, page }) => {
  //style
  const cardBackground = {
    backgroundImage: `url(${course.course_image})`,
    height: "3.5em",
    borderRadius: "5px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    position: "relative",
  };

  // inits
  const history = new useHistory();
  // logics
  const onEnroll = () => {
    localStorage.setItem("course_details", JSON.stringify(course));
    if (page === "home") {
      history.push({
        pathname: "/coursedetails",
      });
    } else {
      window.scroll(0, 0);
      history.replace({
        pathname: "/coursedetails",
      });
    }
  };
  return (
    <div
      id={index}
      className="btn col-md-3"
      style={{ marginTop: "5px" }}
      onClick={onEnroll}
    >
      {/**
                  {course.name}
 * 
                  {course.short_description}
 */}
      <div>
        <div style={infoCardBackground}>
          <div className="row" style={{ marginLeft: "auto", marginRight: "auto" }}>
            <div className="col-2" style={cardBackground}></div>
            <div className="col-10">
              <div className="row">
                <div className="col" style={titleStyle}>
                  <div className="d-flex justify-content-start">
                    {course.name}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col" style={subTitleStyle}>
                  <div className="d-flex justify-content-start">
                    {course.short_description}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const cardTint = {
  width: "100%",
  height: "20em",
  borderRadius: "5px",
  padding: "10px",
  background: `linear-gradient(146deg, rgba(6,78,155,0.5) 0%, rgba(255,255,255,0.5) 100%)`,
};

const infoCardBackground = {
  flexDirection: "column",
  alignItems: "center",
  borderRadius: "5px",
  padding: "10px",
  height: "5em",
  color: "white",
  background:
    "linear-gradient(270deg, rgba(0,0,0,0.3) 0%, rgba(7,78,155,0.8) 100%)",
};

const titleStyle = {
  fontSize: "1.2em",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
};

const subTitleStyle = {
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
};
export default EnrollChip;
