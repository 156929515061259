import React, { useEffect } from "react";
import CommonNavButtons from "../../utils/components/commonNavButtons/CommonNavButtons";
import Navbar from "../../utils/components/navbar/Navbar";
import LoginNormal from "./LoginNormal";
import LoginVoucher from "./LoginVoucher";
import Logo from "../../utils/assets/logo.png";
import LoginBack from "../../utils/assets/loginback.png";
import MediaQuery from "react-responsive";
import LoginPortrait from "./LoginPortrait";

const Login = () => {
  useEffect(() => {
    const background = localStorage.getItem("background");
    localStorage.clear();
    localStorage.setItem("background", background);
  }, []);
  return (
    <>
      <div style={backgroundimage}>
        <MediaQuery orientation="portrait">
          {/* You can also use a function (render prop) as a child */}
          {(matches) =>
            matches ? (
              <LoginPortrait />
            ) : (
              <div
                className="d-flex justify-content-center"
                style={gradientDiv}
              >
                <div style={loginContainer}>
                  <div className="row">
                    <div className="col-md">
                      <div className="jumbotron">
                        <img src={Logo} className="image-fluid" width="250" />
                      </div>
                    </div>
                    <div className="col-md"></div>
                  </div>
                  {/* contians 2 login forms */}
                  <div className="row">
                    {/* login for normal user */}
                    <LoginNormal />
                    {/* login by voucher */}
                    <LoginVoucher />
                  </div>
                </div>
              </div>
            )
          }
        </MediaQuery>
      </div>
    </>
  );
};

const backgroundimage = {
  backgroundImage: `url(${LoginBack})`,
  backgroundRepeat: "no-repeat",
  backgroundAttachment: "fixed",
  backgroundPosition: "center",
  backgroundSize: "cover",
};

const gradientDiv = {
  alignItems: "center",
  height: "100vh",
  width: "100%",
  flexDirection: "column",
  background:
    "linear-gradient(146deg, rgba(255,255,255,1) 2%, rgba(7,78,155,0.8169642857142857) 100%)",
};

const loginContainer = {
  width: "80%",
  background:
    "linear-gradient(146deg, rgba(255,255,255,1) 2%, rgba(234,240,240,0.5) 100%)",
  padding: "4em",
  borderRadius: "10px",
};
export default Login;
