import React from 'react'

const TestingUI = () => {
    return (
        <div>
            Please write test UI code only in this page, if neccessary make files in testingUI folder Only
        </div>
    )
}

export default TestingUI
