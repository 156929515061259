import React, { useEffect, useRef, useState } from "react";
import { Endpoints } from "../../repository/Endpoints";

const VideoViewer = ({ url }) => {
  const [vidUrl, setVidUrl] = useState(url);
  const videoRef = useRef(null);
  function hasMediaSource() {
    return !!(window.MediaSource || window.WebKitMediaSource);
  }

  // if (hasMediaSource()) {
  // } else {
  //   alert("Your browser doesn't support the MediaSource API!");
  // }
  useEffect(async () => {
    const theUrl =
      Endpoints.api +
      Endpoints.education_section_materials_get_video_pseudo_stream +
      localStorage.getItem("material_id");
    // const theUrl =
    //   "https://chub.compliance.monster/storage/uploads/chub_public/education/bp_secured/ge_up_/v2/de/web-201005_cow_deutsch_00.mp4";
    // const theUrl = 'https://nickdesaulniers.github.io/netfix/demo/frag_bunny.mp4';

    const headers = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    // const mimeCodec = 'video/mp4; codecs="avc1.42E01E, mp4a.40.2"';
    // const myMediaSource = new MediaSource();
    // const url = URL.createObjectURL(myMediaSource);
    // videoRef.current.src = url;
    // myMediaSource.addEventListener("sourceopen", () => {
    //   const videoSourceBuffer = myMediaSource.addSourceBuffer(mimeCodec);
    //   videoSourceBuffer.addEventListener("error", console.log);
    //     fetch(theUrl, headers)
    //   //fetch(theUrl)
    //     .then((response) => {
    //       return response.arrayBuffer();
    //     })
    //     .then((videoData) => {
    //       console.log("videodata", videoData);
    //       videoSourceBuffer.appendBuffer(videoData);
    //     });
    // });

    // fetch(theUrl, headers)
    //   //   //fetch(theUrl)
    //   .then((response) => {
    //     return response.arrayBuffer();
    //   })
    //   .then((videoData) => {
    //     videoRef.current.src = videoData;
    //    // videoSourceBuffer.appendBuffer(videoData);
    //   });
  }, []);
  return (
    <>
      {/* <video ref={videoRef} controls /> */}
      <video
        id="myVideo"
        className="embed-responsive-item"
        src={url}
        style={{ width: "100%", height: "auto", borderRadius: "5px" }}
        autoPlay
        controls
      ></video>

      {/* <button className="btn-round">Next</button> */}
    </>
  );
};

export default VideoViewer;
