import back from "../../utils/assets/back.png";
import back2 from "../../utils/assets/back2.png";
import back3 from "../../utils/assets/back3.png";
import back4 from "../../utils/assets/back4.png";

export const Backgrounds = [
    {
        image:{ back },
        title:"back",
    },
    {
        image:{ back2 },
        title:"back2",
    },
    {
        image:{ back3 },
        title:"back3",
    },
    {
        image:{ back4 },
        title:"back4",
    },
]