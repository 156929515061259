import React, { useState } from "react";
import { Endpoints } from "../../repository/Endpoints";
import { PostMaker } from "../../repository/RequestMaker";
import Navbar from "../../utils/components/navbar/Navbar";
import { useHistory } from "react-router-dom";

const RegisterToGo = () => {
  // inits
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [date, setDate] = useState("");
  const image = " ";
  const history = new useHistory();

  //   logics

  const onRegister = () => {
    const data = {
      first_name: firstname,
      last_name: lastname,
      mobile: "123",
      email: email,
      birth_date: date,
      company: company,
      lang: "1",
      trade: "tester",
      user_image: image,
      personal_code: " ",
      tenant_id: localStorage.getItem("tenant_id"),
    };
    PostMaker(data, Endpoints.register_to_go).then((res) => {
      localStorage.setItem("profile_id", res.profile_id);
      localStorage.setItem("token", res.token);
      localStorage.setItem("user_id", res.user_id);
      const course_details = JSON.parse(localStorage.getItem("course_details"));
      PostMaker(
        {
          user_id: res.user_id,
          course_id: course_details.id,
          profile_id: res.profile_id,
        },
        Endpoints.enroll
      ).then((response) => {
        localStorage.setItem("enrollment_id", response.data.id);
        history.push({
          pathname: "/sections",
        });
      });
    });
  };
  return (
    <>
      <Navbar></Navbar>
      <div className="container">
        <div
          className="jumbotron"
          style={registerBox}
        >
          <h4
            style={{
              fontSize: "1.6rem",
              fontWeight: "300",
              lineHeight: "1.2",
            }}
          >
            Add User Information
          </h4>
          {/* <p className="text-muted invisible">with voucher</p> */}
          <hr />
          <div class="form-group">
            <input
              class="form-control"
              placeholder="First name"
              value={firstname}
              onChange={(e) => setFirstname(e.target.value)}
            />
            <small class="form-text text-muted">
              Please enter your first name
            </small>
          </div>

          <div class="form-group">
            <input
              class="form-control"
              placeholder="Last name"
              value={lastname}
              onChange={(e) => setLastname(e.target.value)}
            />
            <small class="form-text text-muted">
              Please enter your last name
            </small>
          </div>

          <div class="form-group">
            <input
              class="form-control"
              placeholder="Company name"
              value={company}
              onChange={(e) => setCompany(e.target.value)}
            />
            <small class="form-text text-muted">
              Please add your Company name
            </small>
          </div>

          <div class="form-group">
            <input
              class="form-control"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <small class="form-text text-muted">
              Please add your email in the correct format. It will be used for
              sending you the certificate
            </small>
          </div>

          <div class="form-group">
            <input
              class="form-control"
              placeholder="Date"
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
            <small class="form-text text-muted">
              Please add your date of birth
            </small>
          </div>

          <div class="form-group">
            <button className="btn btn-outline-primary" onClick={onRegister}>
              Continue
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const registerBox = {
  backgroundColor: "white",
  marginTop: "10px",
  opacity: "0.9",
  borderRadius: "5px",
};

export default RegisterToGo;
