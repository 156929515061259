import React, { useEffect, useState } from "react";
import { Endpoints } from "../../repository/Endpoints";
import { GetMaker } from "../../repository/RequestMaker";
import CommonNavButtons from "../../utils/components/commonNavButtons/CommonNavButtons";
import Navbar from "../../utils/components/navbar/Navbar";
import EnrollCard from "../../utils/components/EnrollCard/EnrollCard";
import { backgroundColor } from "../../utils/backgroundColor";
import { useHistory } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import "./style.css";
import EnrollChip from "../../utils/components/EnrollChip/EnrollChip";
import { useLocalStorage } from "../../utils/components/useLocalStorage/useLocalStorage";
const Home = () => {
  // inits
  const user_id = localStorage.getItem("user_id");
  const [courses, setCourses] = useState([]);
  const userType = localStorage.getItem("userType");
  const history = new useHistory();
  const [isChipView, setIsChipView] = useState(false);
  // logics
  useEffect(() => {
    var path = "";
    if (userType === "normal") {
      path = Endpoints.list_courses + user_id;
    } else {
      path =
        Endpoints.list_courses_voucher +
        localStorage.getItem("tenant_id") +
        "/1";
    }
    GetMaker(path).then((res) => {
      console.log(res.data);
      setCourses(res.data);
    });
  }, []);

  useEffect(() => {
    const isChipViewLS = localStorage.getItem("isChipView");
    console.log("isChipViewLS", isChipViewLS);
    if (isChipViewLS === "true") {
      setIsChipView(true);
    } else {
      setIsChipView(false);
    }
  }, [isChipView]);

  const contentView = (course, key) => {
    if (isChipView) {
      return <EnrollChip course={course} key={key} page="home" />;
    } else {
      return <EnrollCard course={course} key={key} page="home" />;
    }
  };

  const changeView = (id) => {
    const elements = document.getElementsByClassName("viewToggle");
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];
      element.style.color = "black";
    }
    document.getElementById(id).style.color = "#074E9B";
    if (id === "gridViewButton") {
      localStorage.setItem("isChipView", "false");
      setIsChipView(false);
    } else if (id == "chipViewButton") {
      localStorage.setItem("isChipView", "true");
      setIsChipView(true);
    }
  };
  return (
    <>
      <Navbar>{userType === "normal" ? <CommonNavButtons /> : <></>}</Navbar>
      <div className="container-fluid">
        <div
          className="row"
          style={{ marginLeft: "auto", marginRight: "auto" }}
        >
          <div className="col">
            <h4 className="display-4">All Courses</h4>
            <hr />
          </div>
          <div className="col">
            <div className="d-flex justify-content-end">
              <button
                className="btn viewToggle"
                id="gridViewButton"
                onClick={() => changeView("gridViewButton")}
              >
                <Icon.GridFill />
              </button>

              <button
                className="btn viewToggle"
                id="chipViewButton"
                onClick={() => changeView("chipViewButton")}
              >
                <Icon.ViewStacked />
              </button>

              <button
                className="btn viewToggle"
                id="chipViewButton"
                onClick={() => history.push("/settings")}
              >
                <Icon.Gear />
              </button>
            </div>
          </div>
        </div>
        <div
          className="row"
          style={{ marginLeft: "auto", marginRight: "auto" }}
        >
          {courses === [] ? (
            "Loading"
          ) : (
            <>
              {courses.map((course, key) => {
                return (
                  <>
                    {isChipView
                      ? contentView(course, key)
                      : contentView(course, key)}
                  </>
                );
              })}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Home;
