import React from "react";
import Logo from "../../utils/assets/logo.png";
const LoadingMaterial = () => {
  return (
    <div className=" d-flex justify-content-center" style={materialViewerStyle}>
      <img src={Logo} style={{ height: "4em" }} className="img-fluid" alt="" />
      <div className="d-flex justify-content-end" style={{ fontSize: "2em" }}>
        Material Viewer
      </div>
    </div>
  );
};

const materialViewerStyle = {
  height: "40em",
  alignItems: "center",
  flexDirection: "column",
  border: "2px solid #BABABA",
  borderRadius: "5px",
  marginBottom: "10px",
};
export default LoadingMaterial;
