import React from "react";
import * as Icon from "react-bootstrap-icons";
import MediaQuery from "react-responsive";

const QuizBody = ({
  setVisible,
  index,
  toggleVisibility,
  numberOfQuestions,
  question,
  onSubmit,
}) => {
  // inits
  // logics
  const onNext = () => {
    toggleVisibility(index + 1);
  };

  const PortraitQuestions = (question, answer) => {
    return (
      <div className="row">
        <div className="col-2 d-flex justify-content-center">
          <span id={answer.id}></span>
          <label id={answer.id} className="toggle-control" name={question.id}>
            <input
              type="checkbox"
              name={"answer_checkbox" + question.id}
              value={answer.id}
            />
            <span className="control"></span>
          </label>
        </div>
        <div className="col-10">{answer.answer_body}<span id={'ans_body_'+answer.id}></span></div>
      </div>
    );
  };

  const LandScapeQuestions = (question, answer) => {
    return (
      <div className="row">
        <div className="col-1 d-flex justify-content-center">
          <span id={answer.id}></span>
          <label id={answer.id} className="toggle-control" name={question.id}>
            <input
              type="checkbox"
              name={"answer_checkbox" + question.id}
              value={answer.id}
            />
            <span className="control"></span>
          </label>
        </div>
        <div className="col-11">{answer.answer_body}<span id={'ans_body_'+answer.id}></span></div>
      </div>
    );
  };
  return (
    <>
      <div className={"packet " + setVisible} id={index}>
        <div name={"questionContainer" + index} style={quizBody}>
          <div className="row">
            <h6
              style={{
                fontSize: "1.6rem",
                fontWeight: "300",
                lineHeight: "1.2",
              }}
              id="title_text"
            >
              {index + 1}
              {". "}
              {question.question_body}
            </h6>
            <hr />
          </div>

          {question.answers.map((answer, anskey) => {
            return (
              <div name="answerContainer" style={{ width: "100%" }}>
                <MediaQuery orientation="portrait">
                  {(matches) =>
                    matches
                      ? PortraitQuestions(question, answer)
                      : LandScapeQuestions(question, answer)
                  }
                </MediaQuery>
              </div>
            );
          })}
        </div>
        <div
          className="d-flex justify-content-end"
          style={{ marginTop: "-25px", marginRight: "30px" }}
        >
          {index + 1 === numberOfQuestions ? (
            <button className="btn-smol" onClick={onSubmit}>
              <Icon.Check2 />
            </button>
          ) : (
            <button className="btn-smol" onClick={onNext}>
              <Icon.SkipEndFill />
            </button>
          )}
        </div>
      </div>
    </>
  );
};

const quizBody = {
  height: "30em",
  alignItems: "center",
  flexDirection: "column",
  border: "1px solid #BABABA",
  borderRadius: "5px",
  padding: "30px",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
  marginTop: "10px",
};

export default QuizBody;
