import React, { useState, useEffect, useLayoutEffect } from "react";
import CommonNavButtons from "../../utils/components/commonNavButtons/CommonNavButtons";
import Navbar from "../../utils/components/navbar/Navbar";
import QuizBody from "./QuizBody";
import "./style.css";
import { GetMaker, PostMaker } from "../../repository/RequestMaker";
import { Endpoints } from "../../repository/Endpoints";
import { useHistory } from "react-router-dom";
const Quiz = () => {
  //inits
  const history = useHistory();
  const material_id = localStorage.getItem("material_id");
  const enrollment_id = localStorage.getItem("enrollment_id");
  const [questions, setQuestions] = useState([]);

  var que_ids = [];
  var ans_ids = [];
  var ans_response = [];
  var main_response = {
    enrollment_id: enrollment_id,
    test_id: "",
    answers: [],
  };

  //logics
  const toggleVisibility = (index) => {
    const questionbodies = document.getElementsByClassName("packet");
    for (let i = 0; i < questionbodies.length; i++) {
      const questionbody = questionbodies[i];
      questionbody.classList.add("setInvisible");
    }
    document.getElementById(index).classList.remove("setInvisible");
  };

  const onSubmit = () => {
    for (let index = 0; index < que_ids.length; index++) {
      const que_id = que_ids[index];
      collector(que_id);
    }
    main_response.answers = JSON.stringify(ans_response);
    main_response.test_id = localStorage.getItem("test_id");
    console.log("main_response", main_response);

    PostMaker(main_response, Endpoints.result).then((res) => {
      localStorage.setItem("results", JSON.stringify(res.data));
      localStorage.setItem("my_answers", ans_ids);
      const course_details = JSON.parse(localStorage.getItem("course_details"));
      localStorage.setItem("course_title", course_details.name);
      localStorage.setItem("passed", res.data.passed);
      localStorage.setItem("percentage", res.data.percentage);

      const material_ids = JSON.parse(localStorage.getItem("material_ids"));
      const enrollment_id = localStorage.getItem("enrollment_id");

      PostMaker(
        {
          section_material_id: material_id,
          course_enrollment_id: enrollment_id,
        },
        Endpoints.mark_quiz_done
      ).then((response) => {
        console.log(response.data);
        history.push({
          pathname: "/sections",
        });
      });
    });
  };

  const collector = (que_id) => {
    var ans_dictionary = {
      q_id: "",
      ans: [],
    };
    ans_dictionary.q_id = que_id;

    var checked_ans = [];

    //console.log("res_ans", res_ans);

    var checkboxes = document.getElementsByName("answer_checkbox" + que_id);
    var checkboxesChecked = [];
    // loop over them all
    for (var i = 0; i < checkboxes.length; i++) {
      // And stick the checked ones onto an array...
      if (checkboxes[i].checked) {
        checkboxesChecked.push(checkboxes[i]);
      }
    }
    for (let index = 0; index < checkboxesChecked.length; index++) {
      const element = checkboxesChecked[index];
      //console.log("ans", element.value);
      checked_ans.push(element.value);
      ans_ids.push(element.value);
    }
    ans_dictionary.ans = checked_ans;
    //setAnsResponse(ans_dictionary);
    ans_response.push(ans_dictionary);
    //console.log("main_response", main_response);
  };

  setTimeout(() => {
    devOnlyThingy();
  }, 1000);

  useEffect(() => {
    GetMaker(Endpoints.quiz + material_id).then((res) => {
      setQuestions(res.data.questions);
      localStorage.setItem("test_id", res.data.test.id);
    });
  }, []);

  const devOnlyThingy = () => {
    for (let index = 0; index < que_ids.length; index++) {
      const que_id = que_ids[index];
      console.log(que_id);
      collector(que_id);
    }
    console.log(ans_response);
    main_response.answers = JSON.stringify(ans_response);
    main_response.test_id = localStorage.getItem("test_id");
    PostMaker(main_response, Endpoints.result)
      .then((res) => {
        localStorage.setItem("results", JSON.stringify(res.data));
      })
      .then(() => {
        const results = JSON.parse(localStorage.getItem("results"));
        var ans_ids = [];
        for (let i = 0; i < results.correct_answers.length; i++) {
          const element = results.correct_answers[i];
          const answers = element.ans;
          for (let j = 0; j < answers.length; j++) {
            const answer = answers[j];
            ans_ids.push(answer);
          }
        }
        for (let i = 0; i < ans_ids.length; i++) {
          const element = ans_ids[i];
          document.getElementById("ans_body_" + element).innerHTML = ".";
        }
      });
  };
  return (
    <>
      <Navbar />
      <div className="container">
        {questions === []
          ? "Loading"
          : questions.map((question, key) => {
              que_ids.push(question.id);
              return (
                <QuizBody
                  setVisible={key == 0 ? "" : "setInvisible"}
                  index={key}
                  toggleVisibility={toggleVisibility}
                  numberOfQuestions={questions.length}
                  question={question}
                  onSubmit={onSubmit}
                />
              );
            })}
        <div className="d-flex">
          {questions.map((a, key) => {
            return (
              <button
                style={{ marginLeft: "2px", border: "none" }}
                onClick={() => toggleVisibility(key)}
                className="btn btn-outline-primary"
              >
                {key + 1}
              </button>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Quiz;
