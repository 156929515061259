import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { Endpoints } from "../../repository/Endpoints";
import { PDFStreamMaker, PostMaker } from "../../repository/RequestMaker";
const PDFViewer = ({ material_id }) => {
  //inits
  const [isLoading, setIsLoading] = useState(true);
  const [pdfFile, setPdfFile] = useState("");

  //logics

  useEffect(() => {
    window.scrollTo(0, 0);
    PDFStreamMaker(Endpoints.pdf + material_id).then((res) => {
      setPdfFile(res);
      setIsLoading(false);
    });
  }, []);
  return (
    <>
      {isLoading ? (
        <Spinner animation="grow" />
      ) : (
        <>
          <div
            style={{
              height: "90vh",
              marginBottom: "10px",
              // border: "1px solid black",
              paddingBottom: "10px",
            }}
          >
            <iframe
              id="pdf-js-viewer"
              src={"lib/web/viewer.html?file=" + pdfFile}
              title="webviewer"
              frameborder="0"
              width="100%"
              height="100%"
            ></iframe>
          </div>
          <div
            className="d-flex justify-content-end"
            style={{ marginBottom: "10px" }}
          >
            {/* <button className="btn-round">Next</button> */}
          </div>
        </>
      )}
    </>
  );
};

export default PDFViewer;
