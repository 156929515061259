import React, { useState } from "react";
import { PostMaker } from "../../repository/RequestMaker";
import { Endpoints } from "../../repository/Endpoints";
import { useHistory } from "react-router-dom";
const LoginVoucher = () => {
  // inits
  const history = new useHistory();
  const [voucher, setVoucher] = useState("");

  //logics
  const onSubmit = (e) => {
    e.preventDefault();
    PostMaker({}, Endpoints.voucher_validity + voucher).then((response) => {
      localStorage.setItem(
        "tenant_id",
        response.data.course_allowance.tenant_id
      );
      localStorage.setItem("voucher", voucher);
      history.push({
        pathname: "/home",
      });
    });
  };
  return (
    <div className="col-md" style={{ borderLeft: "1px solid #C8C9CA" }}>
      <div className="jumbotron">
        <h4
          style={{
            fontSize: "1.6rem",
            fontWeight: "300",
            lineHeight: "1.2",
          }}
        >
          Start with Voucher
        </h4>
        {/* <p className="text-muted invisible">with voucher</p> */}
        <hr />
        <div class="form-group">
          <input
            class="form-control"
            placeholder="Voucher Code"
            value={voucher}
            onChange={(e) => setVoucher(e.target.value)}
          />
          <small class="form-text text-muted">
            example: xYg-123 
          </small>
        </div>

        <div class="form-group">
          <button className="btn btn-outline-primary" onClick={onSubmit}>
            Login
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginVoucher;
