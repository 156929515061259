import React, { useState, useEffect, useLayoutEffect } from "react";
import { Endpoints } from "../../repository/Endpoints";
import { GetMaker, PostMaker } from "../../repository/RequestMaker";
import CommonNavButtons from "../../utils/components/commonNavButtons/CommonNavButtons";
import Navbar from "../../utils/components/navbar/Navbar";
import Folder from "./Folder";
import VideoViewer from "./VideoViewer";
import PDFViewer from "./PDFViewer";
import { Spinner } from "react-bootstrap";
import LoadingMaterial from "./LoadingMaterial";
import { useHistory } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";

const Sections = () => {
  // inits
  const [pdf, setPdf] = useState("");
  const [video, setVideo] = useState("");
  const course_id = localStorage.getItem("course_id");
  const [materials, setMaterials] = useState([]);
  const [materialAvailable, setMaterialAvailable] = useState(false);
  const [certificateAvailable, setCertificateAvailable] = useState(false);
  const history = new useHistory();
  const enrollment_id = localStorage.getItem("enrollment_id");
  // logics

  const setContentType = (data, type) => {
    setMaterialAvailable(false);
    setTimeout(() => {
      setPdf("");
      setVideo("");
      if (type === "PDF") {
        setPdf(data);
        markMaterialComplete(data).then(() => {
          setMaterialAvailable(true);
        });
        console.log("pdf", pdf, "materialAvailable", materialAvailable);
      } else if (type === "VIDEO") {
        setVideo(data);
        markMaterialComplete(searchVideoMaterialId(data)).then(() => {
          setMaterialAvailable(true);
        });
      } else {
      }
    }, 100);
  };

  const searchVideoMaterialId = (data) => {
    var allmaterial = [];
    materials.forEach((material) => {
      material.materials.forEach((_material) => {
        allmaterial.push(_material);
      });
    });
    const result = allmaterial.find(({ public_video_url }) => public_video_url === data);
    return result.id;
  };

  const materialViewer = () => {
    if ((pdf === "") & (video !== "")) {
      return <VideoViewer url={video} />;
    } else if ((pdf !== "") & (video === "")) {
      return <PDFViewer material_id={pdf} />;
    } else {
      return <LoadingMaterial />;
    }
  };

  const serialize = (sections) => {
    var materialsAll = [];
    var materialsIds = [];
    for (let i = 0; i < sections.length; i++) {
      const materials = sections[i].materials;
      for (let j = 0; j < materials.length; j++) {
        const material = materials[j];
        materialsIds.push(material.id);
        materialsAll.push(material);
      }
    }
    localStorage.setItem("material", JSON.stringify(materialsAll));
    localStorage.setItem("material_ids", JSON.stringify(materialsIds));
    localStorage.setItem("material_length", materialsAll.length);
    localStorage.setItem("current_material", -1);
  };

  const markMaterialComplete = async (material_id) => {
    const enrollment_id = localStorage.getItem("enrollment_id");
    PostMaker(
      {
        section_material_id: material_id,
        course_enrollment_id: enrollment_id,
      },
      Endpoints.education_material_progress
    ).then((response) => {
      //console.log(response.data);
      getCompletedMaterialList();
    });
  };

  const getCompletedMaterialList = async () => {
    GetMaker(Endpoints.material_progress_by_enrollment_id + enrollment_id).then(
      (res) => {
        res.data.forEach((material) => {
          const material_id = material.section_material_id;

          // remove ids from material to see
          const materialIds = JSON.parse(localStorage.getItem("material_ids"));
          const index = materialIds.indexOf(material.section_material_id);
          if (index > -1) {
            materialIds.splice(index, 1);
            localStorage.setItem("material_ids", JSON.stringify(materialIds));
          }

          if (materialIds.length === 0) {
            setCertificateAvailable(true);
          }
          document.getElementById(
            material.section_material_id
          ).innerHTML = `<i class="fa fa-check" aria-hidden="true"></i>`;
        });
      }
    );
  };

  const getCertificate = () => {
    history.push({
      pathname: "/certificate",
    });
  };

  useEffect(() => {
    GetMaker(Endpoints.sections + course_id).then((res) => {
      setMaterials(res.data);
      serialize(res.data);
      setTimeout(() => {
        const materialIds = JSON.parse(localStorage.getItem("material_ids"));
        getCompletedMaterialList();
      }, 50);
    });
  }, []);
  return (
    <>
      <Navbar>
        <CommonNavButtons />
      </Navbar>

      <div className="container-fluid" style={{ height: "100%" }}>
        {certificateAvailable ? (
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-outline-primary"
              onClick={getCertificate}
            >
              Get Certificate
            </button>
          </div>
        ) : (
          <></>
        )}
        <div
          className="row"
          style={{
            height: "100%",
            marginTop: "10px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <div className="col-md-8">
            {materialAvailable ? materialViewer() : materialViewer()}
          </div>
          <div
            className="col-md-4"
            style={{
              background: `linear-gradient(180deg, rgba(6,78,155,0.8) 0%, rgba(255,255,255,0.5) 90%)`,
              borderRadius: "5px",
              padding: "10px",
            }}
          >
            {materials === [] ? (
              <Spinner animation="grow" />
            ) : (
              materials.map((material, key) => {
                return (
                  <Folder material={material} setContentType={setContentType} />
                );
              })
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Sections;

// fetch('https://chub.compliance.monster/api/cleanup_by_enrollment_id/'+localStorage.getItem('enrollment_id'))
//   .then(res => console.log(res))
